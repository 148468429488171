<template>
    <b-alert  variant="success" show>
        <div class="p-2 d-flex">
            <feather-icon icon="BookmarkIcon" size="32" />
            <div class="ml-2">
                <h3>{{ title }}</h3>
                <p>{{ message }}</p>
            </div>
        </div>
    </b-alert>
</template>

<script>
import {
    BAlert
} from "bootstrap-vue";

export default {
    name: 'MatchAlert',
    components: {
        BAlert
    },
    props: {
        title: {
            type: String
        },
    message: {
            type: String
        }
    }
}
</script>
<style lang="scss">
.alert{
  margin-top: 20px;
  h3{
    color: inherit
  }
}
</style>